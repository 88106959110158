<template>
  <div id="shop-list">
    <!-- list filters -->
    <v-card>
      <v-card-title>Shops</v-card-title>
      <v-row class="px-2 ma-0">
        <!-- shop filter -->
        <v-col
          cols="12"
          sm="8"
        >
          <v-select
            v-model="shopFilter"
            placeholder="Select Shop"
            :items="shopFilterData"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Select Status"
            :items="statusFilterData"
            item-text="text"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-btn
          color="primary"
          class="mb-4 me-3"
          :loading="loading"
          @click.prevent="applyFilters"
        >
          Apply Filters
        </v-btn>

        <v-btn
          color="secondary"
          outlined
          class="me-3 mb-4"
          type="reset"
          @click.prevent="resetFilters"
        >
          Reset
        </v-btn>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="listData"
        :search="searchQuery"
        :options.sync="options"
        :server-items-length="totalListData"
        :items-per-page="10"
        :loading="loading"
      >
        <!-- Shop -->
        <template #[`item.shopName`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <span>{{ item.shopName.slice(0, 2).toUpperCase() }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.shopName }}</span>
            </div>
          </div>
        </template>

        <!-- url-->
        <template #[`item.url`]="{ item }">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary text-truncate">{{ item.shopUrl }}</span>
          </div>
        </template>

        <!-- Status -->
        <template #[`item.statusCode`]="{ item }">
          <v-chip
            small
            :color="resolveStatusVariant(item.statusCode)"
            :class="`${resolveStatusVariant(item.statusCode)}--text`"
            class="v-chip-light-bg"
          >
            {{ item.statusCode }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <router-link
            :to="{
              name: 'shop-edit',
              params: { id: item.shopId },
            }"
          >
            <v-icon> {{ icons.mdiPencilBoxOutline }} </v-icon>
          </router-link>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
// sidebar
import {
  mdiAccountOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiCalendar,
  mdiPencil,
  mdiPencilBoxOutline,
} from '@mdi/js'
import {
  onMounted, onUnmounted,
} from '@vue/composition-api'
import { avatarText, formatDate, formatCurrency } from '@core/utils/filter'
import store from '@/store'
import shopsStoreModule from '../shopsStoreModule'

import useShopList from './useShopList'

export default {
  setup() {
    const STORE_MODULE_NAME = 'onboarding-shops'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) { store.registerModule(STORE_MODULE_NAME, shopsStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) { store.unregisterModule(STORE_MODULE_NAME) }
    })

    const {
      listData,
      totalListData,
      tableColumns,
      searchQuery,
      merchantFilter,
      merchantFilterData,
      shopFilter,
      shopFilterData,
      statusFilter,
      statusFilterData,
      loading,
      options,
      selectedRows,
      fetchList,
      applyFilters,
      resetFilters,
      fetchConfig,
      resolveStatusVariant,
    } = useShopList()

    onMounted(() => {
      fetchConfig()
    })

    return {
      listData,
      totalListData,
      tableColumns,
      searchQuery,
      merchantFilter,
      merchantFilterData,
      shopFilter,
      shopFilterData,
      statusFilter,
      statusFilterData,
      loading,
      options,
      selectedRows,
      avatarText,
      resolveStatusVariant,
      fetchList,
      applyFilters,
      resetFilters,
      fetchConfig,
      formatDate,
      formatCurrency,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiCalendar,
        mdiPencil,
        mdiPencilBoxOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/finance/settlement.scss';
</style>
