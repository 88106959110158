var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"shop-list"}},[_c('v-card',[_c('v-card-title',[_vm._v("Shops")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"8"}},[_c('v-select',{attrs:{"placeholder":"Select Shop","items":_vm.shopFilterData,"item-text":"text","item-value":"value","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.shopFilter),callback:function ($$v) {_vm.shopFilter=$$v},expression:"shopFilter"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"placeholder":"Select Status","items":_vm.statusFilterData,"item-text":"text","item-value":"value","outlined":"","dense":"","clearable":"","hide-details":""},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.applyFilters.apply(null, arguments)}}},[_vm._v(" Apply Filters ")]),_c('v-btn',{staticClass:"me-3 mb-4",attrs:{"color":"secondary","outlined":"","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetFilters.apply(null, arguments)}}},[_vm._v(" Reset ")])],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.listData,"search":_vm.searchQuery,"options":_vm.options,"server-items-length":_vm.totalListData,"items-per-page":10,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.shopName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[_c('span',[_vm._v(_vm._s(item.shopName.slice(0, 2).toUpperCase()))])]),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.shopName))])])],1)]}},{key:"item.url",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary text-truncate"},[_vm._v(_vm._s(item.shopUrl))])])]}},{key:"item.statusCode",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.resolveStatusVariant(item.statusCode)) + "--text"),attrs:{"small":"","color":_vm.resolveStatusVariant(item.statusCode)}},[_vm._v(" "+_vm._s(item.statusCode)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{
            name: 'shop-edit',
            params: { id: item.shopId },
          }}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiPencilBoxOutline)+" ")])],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }